//import React from 'react';


export { default as className } from 'merge-class-names'; // https://github.com/wojtekmaj/merge-class-names

export { default as ref } from 'merge-refs';

export const eventProxy = (...listeners) => (event) => listeners.every((listener) => { if (typeof listener !== 'function') return true; listener(event); return !event.defaultPrevented });

export const properties = (text, callback, raw) => {
  text = String(text || '');
  
  if (typeof(callback) !== 'function') {
    if (typeof(callback) !== 'object')
      return text;
    
    const map = { ...callback };
    
    callback = (property, value) => {
      if (!property || !(value in map))
        return;
      
      return map[value];
    };
  }
  
  let start = text.indexOf('{');
  
  if (start >= 0) {
    let last = 0;
    
    const result = [];
    
    while (start >= 0) {
      if (last < start) {
        const value = text.substring(last, start);
        
        let write = callback(false, value);
        
        if (write == null || write == undefined)
          write = value;
        
        result.push(write);
      }
      
      last = text.indexOf('}', start) + 1;
      
      if (last <= 0) {
        const value = text.substring(start);
        
        let write = callback(false, value);
        
        if (write == null || write == undefined)
          write = value;
        
        result.push(write);
        
        break;
      }
      
      const value = text.substring(start + 1, last - 1);
      
      let write = callback(true, value);
      
      if (write == null || write == undefined)
        write = '{' + value + '}';
      
      result.push(write);
      
      start = text.indexOf('{', last);
    }
    
    if (last > 0 && last < text.length) {
      const value = text.substring(last);
      
      let write = callback(false, value);
      
      if (write == null || write == undefined)
        write = value;
      
      result.push(write);
    }
    
    if (!raw)
      return result.join('');
    
    return result;
  } else {
    let write = callback(false, text);
    
    if (write == null || write == undefined)
      write = text;
    
    return write;
  }
};

/* eslint-disable */
//import React from 'react';


export const sqr = (value) => value * value;

export const isNone = (value) => value === undefined || value === null;

export const isObject = (value) => value && typeof value === 'object' && !Array.isArray(value);

export const compare = (value1, value2) => isNone(value1) && isNone(value2) ? 0
  : isNone(value1) ? Infinity
  : isNone(value2) ? -Infinity
  : typeof value1 === 'string' || value1 instanceof String || typeof value2 === 'string' || value2 instanceof String ? String(value1).localeCompare(String(value2))
  : value1 < value2 ? -1
  : value1 > value2 ? 1
  : 0;

export const deepEqual = (value1, value2) => {
  if (value1 === value2)
    return true;
  
  if (isNone(value1) || isNone(value2))
    return false;
  
  const type1 = typeof value1;
  
  if (type1 !== typeof value2 || type1 !== 'object')
    return false;
  
  if (Array.isArray(value1)) {
    if (!Array.isArray(value2) || value1.length !== value2.length)
      return false;
    
    return value1.every((item, index) => deepEqual(item, value2[index]));
  }
  
  if (value1.constructor !== value2.constructor)
    return false;
  
  const keys1 = Object.keys(value1);
  
  if (keys1.length !== Object.keys(value2).length)
    return false;
  
  return keys1.every((key, index) => deepEqual(value1[key], value2[key]));
};

export const deepCopy = (value) => typeof globalThis.structuredClone === 'function' ? globalThis.structuredClone(value) : JSON.parse(JSON.stringify(value));

export const deepMerge = (target, ...source) => {
  if (!isObject(target))
    return target;
  
  source.forEach((src) => {
    if (!isObject(src))
      return;
    
    Object.entries(src).forEach(([key, value]) => {
      if (!isObject(value)) {
        target[key] = value;
        
        return;
      }
      
      if (!isObject(target[key]))
        target[key] = {};
      
      deepMerge(target[key], value);
    });
  });
  
  return target;
};

export const getField = (object, path) => {
  if (!Array.isArray(path)) {
    path = String(path || '').split('.');
    
    if (path.length === 1 && path[0] === '')
      return undefined;
  }
  
  if (!path.length)
    return undefined;
  
  let result = object;
  
  path.forEach((name) => result = result?.[name]);
  
  return result;
};

export const getFields = (object, path) => {
  if (!Array.isArray(path)) {
    path = String(path || '').split('.');
    
    if (path.length === 1 && path[0] === '')
      return [];
  }
  
  if (!path.length)
    return [];
  
  let result = [];
  
  path.forEach((name, index) => {
    if (!index)
      result.push([[undefined, object]]);
    
    if (name === '*') {
      const oldResult = result;
      
      result = [];
      
      oldResult.forEach((current) => {
        if (current[0][1] && typeof current[0][1] === 'object')
          Object.entries(current[0][1]).forEach(([key, value]) => result.push([[key, value], ...current]));
      });
    } else {
      result.forEach((current) => {
        if (current[0][1] && typeof current[0][1] === 'object') {
          current.unshift([name, current[0][1][name]]);
        } else {
          current.unshift([name, undefined]);
        }
      });
    }
  });
  
  return result;
};

export const setFields = (object, path, value) => {
  getFields(object, path).forEach((field) => field.length > 1 && (field[1][1][field[0][0]] = value));
};

export const parseColor = (value) => {
  if (Array.isArray(value)) {
    return [
      Math.min(Math.max(Number(value[0]) || 0, 0), 1),
      Math.min(Math.max(Number(value[1]) || 0, 0), 1),
      Math.min(Math.max(Number(value[2]) || 0, 0), 1),
      Math.min(Math.max(Number(value[3] ?? 1) || 0, 0), 1)
    ];
  }
  
  value = /^\s*((?<hex1>#)(?<hex1R>[0-9a-f])(?<hex1G>[0-9a-f])(?<hex1B>[0-9a-f])(?<hex1A>[0-9a-f])?|(?<hex2>#)(?<hex2R>[0-9a-f]{2})(?<hex2G>[0-9a-f]{2})(?<hex2B>[0-9a-f]{2})(?<hex2A>[0-9a-f]{2})?|(?<rgb>rgba?)\(?(?<rgbR>\d+\.?\d*%?)(\s+|\s*,\s*)(?<rgbG>\d+\.?\d*%?)(\s+|\s*,\s*)(?<rgbB>\d+\.?\d*%?)((\s+|\s*[,\/]\s*)(?<rgbA>\d+\.?\d*%?))?\)?|(?<hsl>hsla?)\(?(?<hslH>\d+\.?\d*(%|turn|deg|rad)?)(\s+|\s*,\s*)(?<hslS>\d+\.?\d*%?)(\s+|\s*,\s*)(?<hslL>\d+\.?\d*%?)((\s+|\s*[,\/]\s*)(?<hslA>\d+\.?\d*%?))?\)?|(?<named>[a-z]+))\s*$/i.exec(value)?.groups;
  
  if (value) {
    try {
      if (value.hex1) {
        return [
          parseInt(value.hex1R, 16) / 15,
          parseInt(value.hex1G, 16) / 15,
          parseInt(value.hex1B, 16) / 15,
          typeof value.hex1A !== 'undefined' ? parseInt(value.hex1A, 16) / 15 : 1
        ];
      } else if (value.hex2) {
        return [
          parseInt(value.hex2R, 16) / 255,
          parseInt(value.hex2G, 16) / 255,
          parseInt(value.hex2B, 16) / 255,
          typeof value.hex2A !== 'undefined' ? parseInt(value.hex2A, 16) / 255 : 1
        ];
      } else if (value.rgb) {
        return [
          Math.min(Math.max(value.rgbR.endsWith('%') ? parseFloat(value.rgbR.substring(0, value.rgbR.length - 1)) / 100 : parseFloat(value.rgbR) / 255, 0), 1),
          Math.min(Math.max(value.rgbG.endsWith('%') ? parseFloat(value.rgbG.substring(0, value.rgbG.length - 1)) / 100 : parseFloat(value.rgbG) / 255, 0), 1),
          Math.min(Math.max(value.rgbB.endsWith('%') ? parseFloat(value.rgbB.substring(0, value.rgbB.length - 1)) / 100 : parseFloat(value.rgbB) / 255, 0), 1),
          typeof value.rgbA !== 'undefined' ? Math.min(Math.max(value.rgbA.endsWith('%') ? parseFloat(value.rgbA.substring(0, value.rgbA.length - 1)) / 100 : parseFloat(value.rgbA), 0), 1) : 1
        ];
      } else if (value.hsl) {
        const h = Math.min(Math.max(value.hslH.endsWith('%') ? parseFloat(value.hslH.substring(0, value.hslH.length - 1)) / 100
          : value.hslH.endsWith('turn') ? parseFloat(value.hslH.substring(0, value.hslH.length - 4))
          : value.hslH.endsWith('deg') ? parseFloat(value.hslH.substring(0, value.hslH.length - 3)) / 360
          : value.hslH.endsWith('rad') ? parseFloat(value.hslH.substring(0, value.hslH.length - 3)) / (Math.PI * 2)
          : parseFloat(value.hslH) / 255, 0), 1);
        
        const s = Math.min(Math.max(value.hslS.endsWith('%') ? parseFloat(value.hslS.substring(0, value.hslS.length - 1)) / 100 : parseFloat(value.hslS) / 255, 0), 1);
        const l = Math.min(Math.max(value.hslL.endsWith('%') ? parseFloat(value.hslL.substring(0, value.hslL.length - 1)) / 100 : parseFloat(value.hslL) / 255, 0), 1);
        
        let r;
        let g;
        let b;
        
        if (s) {
          const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
          const p = 2 * l - q;
          
          r = hueToRgb(p, q, h + 1 / 3);
          g = hueToRgb(p, q, h);
          b = hueToRgb(p, q, h - 1 / 3);
        } else {
          r = g = b = l;
        }
        
        return [
          r,
          g,
          b,
          typeof value.hslA !== 'undefined' ? Math.min(Math.max(value.hslA.endsWith('%') ? parseFloat(value.hslA.substring(0, value.hslA.length - 1)) / 100 : parseFloat(value.hslA), 0), 1) : 1
        ];
      } else if (value.named) {
        const div = document.createElement('div');
        div.style.color = value.named;
        document.body.appendChild(div);
        
        const rgb = window.getComputedStyle(div).getPropertyValue('color');
        
        document.body.removeChild(div);
        
        return parseColor(rgb);
      }
    } catch (e) {
    }
  }
  
  return [0, 0, 0, 1];
};


const hueToRgb = (p, q, t) => {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  
  return p;
};

import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import BigNumber from 'bignumber.js';

// @material-ui
import { Typography, Tooltip } from '@material-ui/core';

// core
import * as convert from '_core/utils/convert';
import { getFormattedPrice } from '_core/utils/formatPrice';
import { useCartContext } from '_core/contexts/Cart';

// style
import { usePriceStyle } from './priceStyle';


const ONE = BigNumber(1);


export const RawPrice = (props) => {
  const {
    className,
    value,
    format,
    currency,
    rootProps,
    hasTooltip,
    tooltipProps,
    renderTooltip,
    useCustomCurrency,
    postfix,
    simple
  } = props

  const classes = usePriceStyle();
  
  const cart = useCartContext();
  
  const currencyData = cart.findCurrency(currency);

  const rootClasses = clsx({
    [classes.root]: true,
    [className]: className != null,
  })

  const content = getFormattedPrice(value, currencyData, format)

  const tooltip =
    typeof renderTooltip === 'function'
      ? renderTooltip({
          value,
          format,
          currency,
          content,
        })
      : content

  if (simple) {
    return (
      <>
        {useCustomCurrency ? `${value} ${currency}` : content}
        {postfix}
      </>
    );
  }

  return (
    <Tooltip
      title={tooltip}
      open={!hasTooltip ? false : undefined}
      {...tooltipProps}
    >
      <Typography
        noWrap={true}
        display="inline"
        component="span"
        className={rootClasses}
        {...rootProps}
      >
        {useCustomCurrency ? `${value} ${currency}` : content}
        {postfix}
      </Typography>
    </Tooltip>
  )
};

RawPrice.defaultProps = {
  simple: false
}

RawPrice.propTypes = {
  // self props
  className: PropTypes.string,
  format: PropTypes.object,
  hasTooltip: PropTypes.bool,
  rootProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  renderTooltip: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  useCustomCurrency: PropTypes.bool,
  Component: PropTypes.elementType,
  postfix: PropTypes.string,
  simple: PropTypes.bool.isRequired
}

export const Price = RawPrice

Price.format = (cart, currency, value, factor = ONE, format = null, priceClassName = null, currencyClassName = null, type = 'jsx') => {
  if (type === 'simple') {
    currency = getCurrency(cart, currency, value);
    
    return Price.round(cart, currency, value, factor, true);
  } else if (type === 'string') {
    currency = getCurrency(cart, currency, value);
    value = Price.round(cart, currency, value, factor, true);
    format = getFormat(cart, currency, format);
    
    return format.replace('%', value);
  } else if (type === 'jsx') {
    currency = getCurrency(cart, currency, value);
    value = Price.round(cart, currency, value, factor, true);
    format = getFormat(cart, currency, format);
    
    const currencyCode = currency.code === 'rur' ? 'RUB' : currency.code.toUpperCase();
    
    return format
      .match(/(%|\s+|[^%\s]+)/g)
      .map((item, index) => /\s+/.test(item) ? <React.Fragment key={index}>&nbsp;</React.Fragment>
        : item === '%' ? <span key={index} className={priceClassName} itemProp="price" content={value}>{value}</span>
        : !/\s/.test(item) ? <span key={index} className={currencyClassName} itemProp="priceCurrency" content={currencyCode}>{item}</span>
        : item);
  } else {
    return Price.round(cart, currency, value, factor, true);
  }
};

Price.round = (cart, currency, value, factor = ONE, stringify = false, type = 'floor') => {
  currency = getCurrency(cart, currency, value);
  value = convert.toBigNumber(value?.value || value);
  factor = convert.toBigNumber(factor, ONE);
  
  if (factor !== ONE)
    value = value.multipliedBy(factor);
  
  let roundMode = BigNumber.ROUND_HALF_EVEN;
  
  if (type === 'ceil') {
    roundMode = BigNumber.ROUND_UP;
  } else if (type === 'floor') {
    roundMode = BigNumber.ROUND_DOWN;
  } else if (type === 'round') {
    roundMode = BigNumber.ROUND_HALF_EVEN;
  }
  
  if (stringify) {
    value = value.toFixed(currency.scale, roundMode).replace(/\.0+$/, '');
  } else {
    value = value.decimalPlaces(currency.scale, roundMode);
  }
  
  return value;
};

export default Price;


const getCurrency = (cart, currency, value) => {
  if (value?.currency?.id)
    currency = value.currency.id;
  
  if (!currency?.code)
    currency = cart.findCurrency(currency);
  
  return currency;
};

const getFormat = (cart, currency, format) => {
  return format && convert.toString(format) || convert.toString(currency?.format) || '%';
};

import React from 'react';
import PropTypes from 'prop-types';

import decorate from '_core/utils/decorate';
import * as http from '_core/utils/http';


const VIDEO_DEFAULT_RATIO = 16 / 9;

const VIDEO_TYPE_MAP = {
  'facebook': {
    url: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F{user}%2Fvideos%2F{id}%2F',
    sources: [
      {
        host: /^(facebook|fb)\.com$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, ...(/(?<user>[^\/]+)\/videos\/(?<id>[^\/]+)/.exec(info.uri === 'plugins/video.php' && http.parse(info.query.href)?.uri || info.uri)?.groups) })
      }
    ]
  },
  
  'kinescope': {
    url: 'https://kinescope.io/embed/{id}',
    autoplay: (show) => show.src = http.param(show.src, 'autoplay=1'),
    loop: (show) => show.src = http.param(show.src, 'loop=1'),
    mute: (show) => show.src = http.param(show.src, 'muted=1'),
    uncontrollable: (show) => show.src = http.param(show.src, 'controls=0&keyboard=0'),
    sources: [
      {
        host: /^kinescope\.io$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, id: /(embed\/)?(?<id>[^\/]+)/.exec(info.uri)?.groups.id })
      }
    ]
  },
  
  'odnoklassniki': {
    url: 'https://ok.ru/videoembed/{id}',
    sources: [
      {
        host: /^(odnoklassniki|ok)\.ru$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, id: /(videoembed|video|live)\/(?<id>[^\/]+)/.exec(info.uri)?.groups.id })
      }
    ]
  },
  
  'rutube': {
    url: 'https://rutube.ru/embed/{id}',
    sources: [
      {
        host: /^rutube\.ru$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, id: /(embed|video)\/(?<id>[^\/]+)/.exec(info.uri)?.groups.id })
      }
    ]
  },
  
  'tiktok': {
    url: 'https://www.tiktok.com/embed/v2/{id}',
    sources: [
      {
        host: /^tiktok\.com$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, id: /@[^\/]+\/video\/(?<id>[^\/]+)/.exec(info.uri)?.groups.id })
      }
    ]
  },
  
  'vimeo': {
    url: 'https://player.vimeo.com/video/{id}',
    sources: [
      {
        host: /^vimeo\.com$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, id: /(?<id>[^\/]+)/.exec(info.uri)?.groups.id })
      },
      {
        host: /^player\.vimeo\.com$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, id: /video\/(?<id>[^\/]+)/.exec(info.uri)?.groups.id })
      }
    ]
  },
  
  'youtube': {
    url: 'https://www.youtube.com/embed/{id}?enablejsapi=1&rel=0&showinfo=0&modestbranding=1',
    autoplay: (show) => show.src = http.param(show.src, 'autoplay=1'),
    loop: (show) => show.src = http.param(show.src, 'loop=1&playlist=' + encodeURIComponent(show.id)),
    mute: (show) => show.src = http.param(show.src, 'mute=1'),
    preview: (show) => show.preview = 'https://img.youtube.com/vi/' + encodeURIComponent(show.id) + '/hqdefault.jpg',
    sources: [
      {
        host: /^youtu\.be$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, id: /(?<id>[^\/]+)/.exec(info.uri)?.groups.id })
      },
      {
        host: /^youtube\.\w+$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, id: info.uri === 'watch' ? info.query.v : /embed\/(?<id>[^\/]+)/.exec(info.uri)?.groups.id })
      }
    ]
  },
  
  'file': {
    url: '{id}',
    autoplay: (show) => show.props.autoPlay = true,
    loop: (show) => show.props.loop = true,
    mute: (show) => show.props.muted = true,
    uncontrollable: (show) => show.props.controls = false,
    sources: [
      {
        src: /\.(m4v|ogv|webm|mp4)$/,
        info: (info, src) => ({ ratio: VIDEO_DEFAULT_RATIO, id: src, props: { controls: true } })
      }
    ]
  }
};

const VIDEO_SOURCE_LIST = Object.entries(VIDEO_TYPE_MAP).flatMap(([code, type]) => (type.sources.forEach((source) => source.type = code), type.sources));


const Video = decorate(class extends React.PureComponent {
  
  static decorateWith = {
  };
  
  static propTypes = {
    type: PropTypes.string.isRequired,
    src: PropTypes.string,
    user: PropTypes.string,
    ratio: PropTypes.number,
    allow: PropTypes.arrayOf(PropTypes.string).isRequired,
    autoplay: PropTypes.bool.isRequired
  };
  
  static defaultProps = {
    type: 'auto',
    allow: ['fullscreen', 'accelerometer', 'autoplay', 'clipboard-write', 'encrypted-media', 'gyroscope', 'picture-in-picture'],
    autoplay: false
  };
  
  
  constructor(props) {
    super(props);
    
    this.iframeRef = React.createRef();
  }
  
  
  renderVideo = (show) => {
    const {
      id,
      className,
      containerClassName,
      dataClassName,
      overlayClassName,
      style,
      alt,
      allow,
      preview,
      uncontrollable,
      before,
      after,
      children,
      
      ...props
    } = this.props;
    
    delete props.type;
    delete props.src;
    delete props.user;
    delete props.ratio;
    delete props.autoplay;
    delete props.loop;
    delete props.mute;
    
    return (
      <div {...props} id={id} className={className} style={{ display: 'inline-block', width: '100%', ...style }}>
        <div className={containerClassName} style={{ position: 'relative', width: '100%', height: 0, paddingBottom: (100 / show.ratio) + '%' }}>
          {before}
          
          {show.preview ? (
            <img
              src={show.preview}
              alt={alt || ''}
              className={dataClassName}
              style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : show.src ? (
            <>
              {show.type === 'file' ? (
                <video
                  ref={this.iframeRef}
                  src={show.src}
                  className={dataClassName}
                  style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
                  {...show.props}
                />
              ) : (
                <iframe
                  ref={this.iframeRef}
                  type="text/html"
                  src={show.src}
                  frameBorder="0"
                  allowFullScreen={allow.indexOf('fullscreen') >= 0}
                  allow={allow.join('; ')}
                  className={dataClassName}
                  style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
                  {...show.props}
                />
              )}
              
              {preview || uncontrollable ? (
                <div className={overlayClassName} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}></div>
              ) : null}
            </>
          ) : children || null}
          
          {after}
        </div>
      </div>
    );
  }
  
  
  componentDidMount() {
    const { id, preview } = this.props;
    
    if (!preview) {
      let set = videoMap.get(id || '');
      
      if (!set)
        videoMap.set(id || '', set = new Set());
      
      set.add(this);
    }
  }
  
  componentWillUnmount() {
    const { id, preview } = this.props;
    
    if (!preview) {
      let set = videoMap.get(id || '');
      
      if (set)
        set.delete(this);
    }
  }
  
  render() {
    const { type, src, user, ratio, preview, autoplay, loop, mute, uncontrollable } = this.props;
    
    const show = {
        type: type || 'auto',
        id: null,
        src,
        user: user || '',
        ratio: Math.max(ratio, 0) || VIDEO_DEFAULT_RATIO
      };
    
    if (!src)
      return this.renderVideo(show);
    
    if (show.src.startsWith('//'))
      show.src = 'https:' + show.src;
    
    const info = http.parse(show.src);
    
    if (show.type === 'auto') {
      if (!info)
        return this.renderVideo(show);
      
      if (info.host?.startsWith('www.'))
        info.host = info.host.substring('www.'.length);
      
      const infoPrepare = VIDEO_SOURCE_LIST.find((source) => {
        let result = false;
        
        if (!result && source.host)
          result = source.host.test(info.host);
        
        if (!result && source.uri)
          result = source.uri.test(info.uri);
        
        if (!result && source.src)
          result = source.src.test(src);
        
        return result;
      });
      
      if (infoPrepare) {
        const infoData = infoPrepare.info(info, show.src);
        
        if (infoData?.id) {
          show.type = infoPrepare.type;
          show.id = infoData.id;
          show.user = infoData.user;
          show.props = infoData.props;
          
          if (!show.ratio)
            show.ratio = infoData.ratio;
        }
      }
    }
    
    const typeInfo = VIDEO_TYPE_MAP[show.type];
    
    if (typeInfo) {
      show.src = typeInfo.url.replace('{id}', show.id).replace('{user}', show.user);
      
      if (preview && typeInfo.preview)
        typeInfo.preview(show);
      
      if (!preview && autoplay && typeInfo.autoplay)
        typeInfo.autoplay(show);
      
      if (!preview && loop && typeInfo.loop)
        typeInfo.loop(show);
      
      if (!preview && mute && typeInfo.mute)
        typeInfo.mute(show);
      
      if ((preview || uncontrollable) && typeInfo.uncontrollable)
        typeInfo.uncontrollable(show);
    }
    
    return this.renderVideo(show);
  }
  
});

Video.get = (id) => {
  if (id === undefined) {
    const result = [];
    
    videoMap.forEach((value, key) => result.push(...Video.get(id || '')));
    
    return result;
  } else {
    const set = videoMap.get(id || '');
    
    return set ? [...set] : [];
  }
};

Video.play = (id) => Video.get(id).forEach((video) => video.iframeRef.current?.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*'));
Video.stop = (id) => Video.get(id).forEach((video) => video.iframeRef.current?.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*'));
Video.pause = (id) => Video.get(id).forEach((video) => video.iframeRef.current?.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*'));

export default Video;


const videoMap = new Map();

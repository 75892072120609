import React from 'react';

import { debounce } from 'lodash';

import * as convert from '_core/utils/convert';
import * as storage from '_core/utils/storage';
import * as cache from '_core/utils/cache';
import * as math from '_core/utils/math';
import * as api from '_core/utils/api';
import { useVisitorInfo } from '_core/utils/visitorInfo';
import AddressInput from '_core/components/AddressInputNew';

import { CURRENCY_FORMAT_CHAR } from 'basic/config';


const GOODS_TIMEOUT = 60 * 60000; // 1 hour

const GOODS_REST_TIMEOUT = 5 * 60000; // 5 minutes

const STORAGE_CART_DATA_KEY = 'shop-cart-data';

const STORAGE_FAVORITE_DATA_KEY = 'shop-favorite-data';

const WAREHOUSE_STORAGE_KEY = 'shop-warehouse';

const WAREHOUSE_CACHE = {};

const DEFAULT_CURRENCY = {
    code: '',
    title: '',
    scale: 0,
    format: CURRENCY_FORMAT_CHAR,
  };

const QUERY_INFO_FIELDS = {
    length: { byGoodsList: (goods) => goods.length },
    
    count_total: { byGoodsGroup: (group) => group.count },
    price_total: { byGoodsGroup: (group) => group.price * group.count },
    discount_total: { byGoodsGroup: (group) => group.discount * group.count },
    price_with_discount_total: { byGoodsGroup: (group) => (group.price - group.discount) * group.count },
    points_total: { byGoodsGroup: (group) => group.points * group.count },
    
    favorite_length: { byFavoriteList: (favorites) => favorites.length }
  };


export const CartContext = React.createContext();

export const CartProvider = ({ children }) => {
  const visitorInfo = useVisitorInfo();
  
  const [goodsState, setGoodsState] = React.useState(null);
  const [goodsInfo, setGoodsInfo] = React.useState({});
  const [goodsRest, setGoodsRest] = React.useState({});
  const [currency, setCurrency] = React.useState(DEFAULT_CURRENCY);
  
  const modalRef = React.useRef();
  const modalBtnRef = React.useRef();
  
  const currencyList = api.useMoneyCurrencyList(visitorInfo.info?.id, (result) => result.data?.list?.length && setCurrency((currency) => result.data.current.id === currency.id ? currency : result.data.list.find((item) => item.id === result.data.current.id) || result.data.list[0]));
  
  
  const storeData = React.useCallback(debounce((currency, state) => {
    storageStoreData(currency, state, 'goods');
  }, 500), []);
  
  const storeFavoriteData = React.useCallback(debounce((currency, state) => {
    storageStoreData(currency, state, 'favorites');
  }, 500), []);
  
  const loadApiData = (currency, data, update) => {
    setGoodsState((state) => {
      if (state.update !== update)
        return state;
      
      state = { ...state, goods: buildApiGoods(data.list), message: data.message, isNext: data.next, isPromocode: data.promocode };
      
      calcQueryInfo(state);
      storeData(currency, state);
      storeFavoriteData(currency, state);
      
      return state;
    });
  };
  
  const fetchData = React.useCallback(debounce((currency, state) => {
    const update = state?.update || 0;
    
    api.request('store/cart/sync', { currency: currency.code })
      .then((data) => loadApiData(currency, data, update))
      .catch((e) => null);
  }, 500), []);
  
  const submitData = React.useCallback(debounce((currency, state) => {
    const update = state?.update || 0;
    
    const list = serializeApiList(state);
    
    api.request('store/cart/sync', { currency: currency.code, list })
      .then((data) => loadApiData(currency, data, update))
      .catch((e) => null);
  }, 500), []);
  
  const setGoodsInfoMap = (map) => map && setGoodsInfo((goodsInfo) => {
      let modified = null;
      
      Object.entries(map).forEach(([id, item]) => {
        const goods = item ? {
          ...item.goods,
          
          price: item.price,
          price_map: item.price_map,
          discount: item.discount,
          amount: item.amount,
          points: item.points
        } : null;
        
        if (math.deepEqual(goodsInfo[id], goods))
          return;
        
        if (!modified)
          modified = { ...goodsInfo };
        
        modified[id] = goods;
      });
      
      return modified || goodsInfo;
    });
  
  const setGoodsRestMap = (map) => map && setGoodsRest((rest) => {
      let modified = null;
      
      Object.entries(map).forEach(([key, val]) => {
        val = convert.toInt(val);
        
        if (rest[key] === val)
          return;
        
        if (!modified)
          modified = { ...rest };
        
        modified[key] = val;
      });
      
      return modified || rest;
    });
  
  
  const cart = {
    ...goodsState,
    
    getControl: (goods) => {
      const id = convert.toUInt(goods?.goods?.id || goods?.id);
      
      if (cart.cityData && !cart.cityRequest && !cart.cityData.isConfirmed) {
        cart.cityRequest = true;
        
        setGoodsState((state) => ({ ...state, cityRequest: true }));
      }
      
      const control = {
        get info() {
          if (!goods?.id)
            return { ...goods, group: [], isLoading: false };
          
          const data = control.data;
          
          if (!goodsState || typeof Window === 'undefined')
            return { ...goods, ...data, isLoading: true };
          
          cache.get(
              null,
              'shop-goods',
              (context, reduce) => api.request('store/goods/list', { ids: reduce, limit: reduce.length }).then((data) => data.list?.reduce((result, item) => (result[item.goods?.id] = item, result), {})),
              GOODS_TIMEOUT, // clearMillis
              goods.id, // reduceKey
              100 // reduceMillis
            )
            .then(setGoodsInfoMap)
            .catch((error) => {
              console.error(error);
              
              setGoodsInfoMap(error.result);
            });
          
          const info = goodsInfo[goods.id];
          
          if (typeof info === 'undefined')
            return { ...goods, ...data, isLoading: true };
          
          if (!info && data?.group)
            setTimeout(() => control.remove(), 1);
          
          return { ...info, ...data, isLoading: false };
        },
        
        get data() {
          return id && goodsState
            ?.goods
            ?.find((item) => item.id === id) || null;
        },
        
        get count() {
          return control.data
            ?.group
            ?.reduce((prev, item) => item.is_modifiable ? prev + item.count : prev, 0) || 0;
        },
        
        get countTotal() {
          return control.data
            ?.group
            ?.reduce((prev, item) => prev + item.count, 0) || 0;
        },
        
        setCount: (callback) => {
          if (!id || !callback)
            return;
          
          setGoodsState((state) => {
            if (!state)
              return state;
            
            let goodsStored = true;
            
            let goodsItem = state.goods?.find((item) => item.id === id);
            
            if (!goodsItem) {
              goodsStored = false;
              
              goodsItem = { id, group: [] };
            }
            
            const rest = control.rest;
            
            const oldCount = goodsItem.group.reduce((prev, item) => item.is_modifiable ? prev + item.count : prev, 0) || 0;
            
            const newCount = Math.min(convert.toUInt(callback(oldCount)), rest.isLoading ? Infinity : rest.value);
            
            //console.log('----------[', newCount, '] - [', oldCount, '] = [', newCount - oldCount, '], rest: [', rest.isLoading ? Infinity : rest.value, ']');
            
            if (newCount === oldCount)
              return state;
            
            state = { ...state, update: state.update + 1 };
            
            if (newCount > oldCount) {
              if (!goodsStored)
                state.goods = state.goods ? [...state.goods, goodsItem] : [goodsItem];
              
              let groupItem = goodsItem.group.find((item) => item.is_modifiable);
              
              if (!groupItem) {
                goodsItem.group.unshift(groupItem = {
                  count: 0,
                  
                  price: convert.toUFloat(goods.price),
                  discount: convert.toUFloat(goods.discount),
                  points: convert.toUFloat(goods.points),
                  
                  is_modifiable: true
                });
              }
              
              groupItem.count += newCount - oldCount;
            } else {
              let diff = oldCount - newCount;
              
              goodsItem.group = goodsItem.group.filter((item) => {
                if (diff <= 0 || !item.is_modifiable)
                  return item.count > 0;
                
                const countSub = Math.max(Math.min(item.count, diff), 0);
                
                item.count -= countSub;
                diff -= countSub;
                
                return item.count > 0;
              });
              
              if (!goodsItem.group.length)
                state.goods = state.goods?.filter((item) => item.group.length > 0);
            }
            
            calcQueryInfo(state);
            
            //console.log('----------store: ', JSON.stringify(state));
            
            storeData(currency, state);
            submitData(currency, state);
            
            return state;
          });
        },
        
        remove: () => {
          control.setCount((count) => 0);
        },
        
        decrease: () => {
          control.setCount((count) => count - 1);
        },
        
        increase: () => {
          control.setCount((count) => count + 1);
        },
        
        toggle: () => {
          control.setCount((count) => count ? 0 : 1);
        },
        
        // favorites
        
        get favoriteData() {
          return id && goodsState
            ?.favorites
            ?.find((item) => item.id === id) || null;
        },
        
        get isFavorite() {
          return !!control.favoriteData;
        },
        
        toggleFavorite(value) {
          if (!id)
            return;
          
          setGoodsState((state) => {
            if (!state)
              return state;
            
            if (!value && value !== false)
              value = !state.favorites?.some((item) => item.id === id);
            
            if (value) {
              if (!state.favorites?.some((item) => item.id === id)) {
                state = { ...state, update: state.update + 1 };
                
                if (!state.favorites)
                  state.favorites = [];
                
                let group = null;
                
                if (!goods.price && Array.isArray(goods.group) && goods.group.length) {
                  group = goods.group.find((item) => item.is_modifiable);
                  
                  if (!group)
                    group = goods.group[0];
                  
                  if (group) {
                    group = {
                      count: 1,
                      
                      price: convert.toUFloat(group.price),
                      discount: convert.toUFloat(group.discount),
                      points: convert.toUFloat(group.points),
                      
                      is_modifiable: true
                    };
                  }
                }
                
                if (!group) {
                  group = {
                    count: 1,
                    
                    price: convert.toUFloat(goods.price),
                    discount: convert.toUFloat(goods.discount),
                    points: convert.toUFloat(goods.points),
                    
                    is_modifiable: true
                  };
                }
                
                state.favorites.push({ id, group: [group] });
              }
            } else {
              if (state.favorites) {
                state = {
                  ...state,
                  
                  update: state.update + 1,
                  favorites: state.favorites.filter((item) => item.id !== id)
                };
              }
            }
            
            calcQueryInfo(state);
            
            storeFavoriteData(currency, state);
            
            return state;
          });
        },
        
        // rest
        
        get rest() {
          if (!id || !cart.warehouse)
            return { isLoading: false, value: 0 };
          
          if (cart.warehouse.is_infinity_goods)
            return { isLoading: false, value: Infinity };
          
          if (!goodsState || typeof Window === 'undefined')
            return { isLoading: true, value: 0 };
          
          cache.get(
              null,
              ['shop-goods-rest', cart.warehouse.id],
              (context, reduce) => api.request('delivery/warehouse/rest', { data: { [cart.warehouse.id]: reduce } }).then((data) => data[cart.warehouse.id]),
              GOODS_REST_TIMEOUT, // clearMillis
              id, // reduceKey
              100 // reduceMillis
            )
            .then(setGoodsRestMap)
            .catch((error) => {
              console.error(error);
              
              setGoodsRestMap(error.result);
            });
          
          const rest = goodsRest[id];
          
          if (typeof rest === 'undefined')
            return { isLoading: true, value: 0 };
          
          return { isLoading: false, value: rest || 0 };
        }
      };
      
      return control;
    },
    
    clearMessage: () => setGoodsState((state) => ({ ...state, message: null })),
    
    isDeliveryRequired: () => {
      const result = { isLoading: false, value: true };
      
      cart.goods.forEach((goods) => {
        const control = cart.getControl(goods);
        
        const info = control.info;
        
        if (info.isLoading) {
          result.isLoading = true;
          result.value = false;
        } else if (!info.is_delivery_required) {
          result.value = false;
        }
      });
      
      return result;
    },
    
    agreementArticleList: () => {
      const result = { isLoading: false, value: [] };
      
      cart.goods.forEach((goods) => {
        const control = cart.getControl(goods);
        
        const info = control.info;
        
        if (info.isLoading) {
          result.isLoading = true;
        } else if (info.agreement_article_list?.length) {
          result.value.push(...info.agreement_article_list);
        }
      });
      
      return result;
    },
    
    getGroupControl: (goods, index) => {
      const id = convert.toUInt(goods?.id);
      
      index = convert.toUInt(index);
      
      const control = cart.getControl(goods);
      
      const groupControl = {
        get data() {
          return control.data;
        },
        
        get count() {
          return groupControl.data
            ?.group?.[index]
            ?.count || 0;
        },
        
        setCount: (callback) => {
          if (!id || !callback)
            return;
          
          setGoodsState((state) => {
            if (!state)
              return state;
            
            let goodsStored = true;
            
            let goodsItem = state.goods?.find((item) => item.id === id);
            
            if (!goodsItem)
              return state;
            
            let groupItem = goodsItem.group[index];
            
            if (!groupItem)
              return state;
            
            const rest = groupControl.rest;
            
            const oldCount = groupItem.count;
            
            const newCount = Math.min(convert.toUInt(callback(oldCount)), rest.isLoading ? Infinity : rest.value);
            
            if (newCount === oldCount)
              return state;
            
            state = { ...state, update: state.update + 1 };
            
            groupItem.count = newCount;
            
            calcQueryInfo(state);
            
            storeData(currency, state);
            submitData(currency, state);
            
            return state;
          });
        },
        
        remove: () => {
          groupControl.setCount((count) => 0);
        },
        
        decrease: () => {
          groupControl.setCount((count) => count - 1);
        },
        
        increase: () => {
          groupControl.setCount((count) => count + 1);
        },
        
        toggle: () => {
          groupControl.setCount((count) => count ? 0 : 1);
        },
        
        // favorites
        
        get favoriteData() {
          return control.favoriteData;
        },
        
        get isFavorite() {
          return control.isFavorite;
        },
        
        toggleFavorite(value) {
          return control.toggleFavorite(value);
        },
        
        // rest
        
        get rest() {
          const rest = control.rest;
          
          if (rest.isLoading)
            return rest;
          
          const goodsItem = goodsState.goods?.find((item) => item.id === id);
          
          if (!goodsItem)
            return rest;
          
          const groupItem = goodsItem.group[index];
          
          if (!groupItem)
            return rest;
          
          const restValue = rest.value - goodsItem.group.reduce((prev, item) => item === groupItem ? prev : prev + item.count, 0);
          
          if (rest.value === restValue)
            return rest;
          
          return { isLoading: false, value: restValue };
        }
      };
      
      return groupControl;
    },
    
    fetchData: () => fetchData(currency, goodsState),
    
    submitData: () => submitData(currency, goodsState),
    
    clear: () => {
      setTimeout(() => setGoodsState((state) => {
        if (!state || !state.goods?.length)
          return state;
        
        state = {
          ...state,
          
          update: state.update + 1,
          goods: null
        };
        
        calcQueryInfo(state);
        
        storeData(currency, state);
        submitData(currency, state);
        
        return state;
      }), 1);
    },
    
    clearFavorites: () => {
      setTimeout(() => setGoodsState((state) => {
        if (!state || !state.favorites?.length)
          return state;
        
        state = {
          ...state,
          
          update: state.update + 1,
          favorites: null
        };
        
        calcQueryInfo(state);
        
        storeFavoriteData(currency, state);
        
        return state;
      }), 1);
    },
    
    // currency
    
    currency,
    
    currencyList: currencyList.data?.list || [DEFAULT_CURRENCY],
    
    findCurrency: (id) => currencyList.data?.list?.find((item) => item.id == id || item.code === id)
      || currency
      || currencyList.data?.list?.length && currencyList.data.list[0]
      || DEFAULT_CURRENCY,
    
    setCurrency: (id) => {
      const newCurrency = currencyList.data?.list?.find((item) => item.id == id);
      
      if (!newCurrency || newCurrency.id === currency.id)
        return;
      
      setCurrency(newCurrency);
    },
    
    // city
    
    get citySimple() {
      return AddressInput.buildSimple('delivery', 'city', cart.cityData);
    },
    
    cityValue: (reverse = false) => AddressInput.buildValue('delivery', 'city', cart.cityData, reverse),
    
    setCityData: debounce((cityData) => {
      const cityChanged = AddressInput.buildValue('delivery', 'city', cityData) !== AddressInput.buildValue('delivery', 'city', cart.cityData);
      
      if (!cityChanged && cityData?.isConfirmed === cart.cityData?.isConfirmed)
        return;
      
      if (cityChanged)
        cart.setWarehouse(null);
      
      setGoodsState((state) => ({ ...state, cityData, cityRequest: cityData?.isConfirmed ? false : state.cityRequest }));
      
      if (!cityData)
        return;
      
      if (cityData.isConfirmed)
        AddressInput.store(cityData);
    }, 10),
    
    cityConfirm: () => cart.setCityData(cart.cityData ? { ...cart.cityData, isConfirmed: true } : null),
    
    // warehouse
    
    setWarehouse: debounce((warehouse) => {
      if (warehouse?.id === cart.warehouse?.id)
        return;
      
      setGoodsState((state) => ({ ...state, warehouse }));
      
      if (warehouse)
        storage.set(WAREHOUSE_STORAGE_KEY, warehouse.id);
    }, 10),
    
    // modal
    
    setModalRef: (value) => modalRef.current = value,
    
    setModalBtnRef: (value) => modalBtnRef.current = value,
    
    isModal: (el) => modalRef.current?.contains(el) || modalBtnRef.current?.contains(el),
    
    get isModalVisible() {
      return goodsState.modalVisible;
    },
    
    setModalVisible: (modalVisible) => setGoodsState((state) => ({ ...state, modalVisible })),
    
    toggleModalVisible: () => setGoodsState((state) => ({ ...state, modalVisible: !state.modalVisible }))
    
    //
  };
  
  const city = cart.cityValue();
  
  cart.warehouseList = cache.use(
      WAREHOUSE_CACHE,
      city,
      (context) => api.request('delivery/warehouse/list', { city }).then((data) => data.list || []),
      (result) => {
        if (result.error || !result.data.length || cart.warehouse)
          return;
        
        const warehouseId = storage.get(WAREHOUSE_STORAGE_KEY);
        
        const warehouse = result.data.find((item) => item.id == warehouseId);
        
        cart.setWarehouse(warehouse || result.data[0]);
      }
    ) || null;
  
  React.useEffect(() => {
    if (!currency.code)
      return;
    
    const startState = {
      update: 0,
      
      message: null,
      isNext: true,
      isPromocode: false,
      
      goods: null,
      favorites: null
    };
    
    storageLoadData(currency, startState, 'goods');
    storageLoadData(currency, startState, 'favorites');
    
    calcQueryInfo(startState);
    setGoodsState(startState);
  }, [currency.code]);
  
  React.useEffect(() => {
    if (!currency.code)
      return;
    
    fetchData(currency, goodsState);
  }, [currency.code, visitorInfo.info?.id]);
  
  React.useEffect(() => {
    if (!visitorInfo.info?.id)
      return;
    
    AddressInput.locate([visitorInfo.info.country?.title, visitorInfo.info.region?.title, visitorInfo.info.city?.title].join(', '))
      .then((data) => cart.setCityData(data))
      .catch((error) => cart.setCityData(null));
  }, [visitorInfo.info?.id]);
  
  return (
    <CartContext.Provider value={cart}>
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const context = React.useContext(CartContext);
  
  if (!context)
    throw new Error('useCartContext should be used inside the CartProvider');
  
  return context;
};


const buildApiGoods = (list) => {
  if (!Array.isArray(list))
    return null;
  
  const result = [];
  
  const map = new Map();
  
  list.forEach((item) => {
    const id = convert.toInt(item[0]);
    const count = convert.toUFloat(item[1]);
    
    if (!id || !count)
      return;
    
    let goods = map.get(id);
    
    if (!goods) {
      goods = { id, group: [] };
      
      result.push(goods);
      
      map.set(id, goods);
    }
    
    const price = convert.toUFloat(item[2]);
    const discount = convert.toUFloat(item[3]);
    const points = convert.toUFloat(item[4]);
    const is_modifiable = !!item[5];
    
    goods.group.push({
      count,
      
      price,
      discount,
      points,
      
      is_modifiable
    });
  });
  
  return result;
};

const calcQueryInfo = (state) => {
  Object.entries(QUERY_INFO_FIELDS).forEach(([key, value]) => {
    let stateValue = 0;
    
    if (state.goods) {
      if (typeof value.byGoodsList === 'function')
        stateValue += Math.max(value.byGoodsList(state.goods), 0);
      
      if (typeof value.byGoodsGroup === 'function')
        state.goods.forEach((goods) => goods.group.forEach((group) => stateValue += Math.max(value.byGoodsGroup(group), 0)));
    }
    
    if (state.favorites) {
      if (typeof value.byFavoriteList === 'function')
        stateValue += Math.max(value.byFavoriteList(state.favorites), 0);
      
      if (typeof value.byFavoriteGroup === 'function')
        state.favorites.forEach((goods) => goods.group.forEach((group) => stateValue = Math.max(value.byFavoriteGroup(group), 0)));
    }
    
    state[key] = stateValue;
  });
};

const storageStoreData = (currency, state, type) => {
  const goods = state?.[type];
  
  const list = goods?.length ? goods.map((item) => [
      item.id,
      
      item.group.map((group) => [
        group.count,
        group.price,
        group.discount,
        group.points,
        group.is_modifiable
      ])
    ]) : null;
  
  if (type === 'goods') {
    storage.set(STORAGE_CART_DATA_KEY, list && [currency.code, list]);
  } else if (type === 'favorites') {
    storage.set(STORAGE_FAVORITE_DATA_KEY, list && [currency.code, list]);
  }
};

const storageLoadData = (currency, state, type) => {
  let data;
  
  if (type === 'goods') {
    data = storage.get(STORAGE_CART_DATA_KEY);
  } else if (type === 'favorites') {
    data = storage.get(STORAGE_FAVORITE_DATA_KEY);
  } else {
    return;
  }
  
  state[type] = [];
  
  if (!Array.isArray(data))
    return;
  
  const cartCurrency = data[0];
  const cartList = data[1];
  
  if (cartCurrency !== currency.code || !Array.isArray(cartList))
    return;
  
  cartList.forEach((cartItem) => {
    if (!Array.isArray(cartItem))
      return;
    
    const id = convert.toUInt(cartItem[0]);
    const group = cartItem[1];
    
    if (!id || !Array.isArray(group))
      return;
    
    const item = {
        id,
        group: group
          .map((groupItem) => Array.isArray(groupItem) && {
              count: convert.toUFloat(groupItem[0]),
              price: convert.toUFloat(groupItem[1]),
              discount: convert.toUFloat(groupItem[2]),
              points: convert.toUFloat(groupItem[3]),
              is_modifiable: !!groupItem[4]
            })
          .filter((groupItem) => groupItem?.count > 0)
      };
    
    if (!item.group.length)
      return;
    
    state[type].push(item);
  });
};

const serializeApiList = (state) => state?.goods?.map(({ id, group }) => [id,group.reduce((prev, item) => item.is_modifiable ? prev + item.count : prev, 0)]) || [];
